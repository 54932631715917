
import React, { useEffect, useRef, useState } from "react";
import './textscoller.scss';
import { FormattedHTMLMessage } from "gatsby-plugin-intl";
import { OTHER_REQ_OPTIONS } from "./../../utils/constants";

const TextScoller = () => {
  const [textWidth, setTextWidth] = useState(0);
  const textRef = useRef(null);

  useEffect(() => {
    if (textRef.current) {
      setTextWidth(textRef.current.scrollWidth);
    }

    const handleResize = () => {
      if (textRef.current) {
        setTextWidth(textRef.current.scrollWidth);
      }
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // To set the fixed animation duration for movement
  const duration = textWidth > 0 ? `${Math.max(textWidth / 120, 15)}s` : '100s';


  return (
    <div className="moving-banner-container">
      <div className="moving-banner" 
        style={{ animationDuration: duration }}
        ref={textRef}
      >
      <FormattedHTMLMessage
              id={
                OTHER_REQ_OPTIONS[process.env.GATSBY_PARTNER][
                  process.env.GATSBY_COUNTRY
                ].TEXT_SCOLLER
              }
            />
      </div>
    </div>
  );
};

export default TextScoller;